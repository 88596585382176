import $ from 'jquery'


export default class Validation {
  constructor(args) {
    const defaults = {
      errorClass: 'input-error',
      errorContainer: '.form__error',
      mailPattern: /^(([^<>()[\]\\.,,:\s@"]+(\.[^<>()[\]\\.,,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      mailEmpty: 'Das E-Mail feld darf nicht leer sein',
      mailSyntax: 'Die E-Mail Syntax ist falsch',
      dateValidation: false,
      dateValidationFormat: 'YYYY-MM-DD',
      customSelectBoxes: '.custom-select-box',
      withCustomSelectBoxes: false,
      customCheckBoxes: '.custom-check-box',
      withCustomCheckBoxes: true,
      withCustomFileUpload: true,
    }
    this.options = { ...defaults, ...args };
  }

  scrollToElement() {
    $('html, body').animate(
      {
        scrollTop: $('.order-form-items.show-step, .contact-form, .overview').offset().top - 50
      },
      'slow'
    );
  }

  checkRequired($parent, secondMail = true) {
    let returnError = [];

    $parent.find('.required').each((i, el) => {
      const $el = $(el);

      if ($el.is(':visible')) {
        const currentFieldName = $el.attr('name');
        const currentSelected = $el.find('option:selected').val();

        let field = '';
        let errorMessage = '';

        if (this.options.withCaptcha) {
          if ($el.attr('name') === 'captcha') {
            if (!this.checkForCaptcha($el.val())) {
              field = currentFieldName;
              errorMessage = $el.data('error-message');
            }
          }
        }

        // If input field is an email field
       if ($el.data('regex')) {
          const regex = new RegExp($el.data('regex'), 'g');

          if (!$el.val().match(regex)) {
            field = currentFieldName;
          } else {
            delete returnError[i];
          }
        } else if ($el.attr('type') === 'email') {
          const correctMail = this.checkIfEmailAnReturnError($el, secondMail);

          if (correctMail !== 'passed') {
            field = currentFieldName;
            errorMessage = correctMail;
          } else {
            delete returnError[i];
          }
        } else if ($el.attr('type') === 'number') {
          if (parseInt($el.val()) === 0 ) {
            field = currentFieldName;
          } else {
            delete returnError[i];
          }
        } else if ($el.is('input') || $el.is('textarea')) {
          if ( $el.attr('type') === 'checkbox') {
            if (! $el.prop('checked') ) {
              field = currentFieldName;
            } else {
              delete returnError[i];
            }
          } else {
            if ($el.val().trim() === '') {
              field = currentFieldName;
            } else {
              delete returnError[i];
            }
          }
          // select boxes
        } else if ($el.is('select') && $el.attr('multiple')) {
          if ($el.val().length === 0 ) {
            field = currentFieldName;
          } else {
            delete returnError[i];
          }
        } else {
          if ($.inArray(currentSelected, this.options.checkArray) !== -1) {
            field = currentFieldName;
          } else {
            delete returnError[i];
          }
        }
        // Push errors in Array
        errorMessage = errorMessage.length === 0 ? $el.data('error-message') : errorMessage;

        //Only push if error is up
        if (field) {
          returnError.push({ field, errorMessage });
        }
      }
    });

    // Check Fileuploads
    if (this.options.withCustomFileUpload) {
      const $fileUploadContainer = $('.file-upload.required')
      if ($fileUploadContainer.is(':visible')) {
        if ( $fileUploadContainer.length > 0) {
          $fileUploadContainer.each((key, element) => {
            const $element = $(element)
            if ( $element.is(':visible')) {
              if (!$element.hasClass('has-files')) {
                let errorMessage = $element.attr('data-error-message')
                let field = $element.attr('data-error-name')

                returnError.push({field, errorMessage})
              }
            }
          })
        }
      }
    }

    // check for Custom Selectboxes
    if (this.options.withCustomSelectBoxes) {
      const $customSelectBoxes = $(this.options.customSelectBoxes)
      if ( $customSelectBoxes.length > 0) {
        $customSelectBoxes.each((key, element) => {
          const $element = $(element)
          if ($element.is(':visible')) {
            const $input = $element.find('input.required')
            const inputValue = $input.val()
            let errorMessage = $element.attr('data-error-message')
            let field = $input.attr('name')

            if (inputValue.length <= 0) {
              returnError.push({field, errorMessage})
            }
          }
        })
      }
    }
    // Check for Custom Checkboxes
    if (this.options.withCustomCheckBoxes) {
      const $customCheckBox = $(this.options.customCheckBoxes)

      if ( $customCheckBox.length > 0 && $customCheckBox.is(':visible')) {
        let isChecked = false

        $customCheckBox.find('label').each((key, element) => {
          const $element = $(element)
          if ($element.is(':visible')) {
            const $input = $element.find('input')

            if ($input.is(':checked')) {
              isChecked = true
            }
          }
        })
        if (!isChecked) {
          let errorMessage = $customCheckBox.attr('data-error-message')
          let field = $customCheckBox.attr('data-error-name')
          returnError.push({field, errorMessage})
        }
      }
    }

    if (this.options.dateValidation) {
      $parent.find('input[data-date]').each((i, el) => {
        let errorMessage = '';
        let field = '';
        const $el = $(el);
        const currentDateName = $el.attr('name');
        const currentDate = $el.val();
        if (currentDate.length > 0) {
          if (this.isValidDate(currentDate) === null) {
            field = currentDateName;
            errorMessage = $el.data('error-message');
          }
          const errorCategory = $el.data('error-category');

          //Only push if error is up
          if (field) {
            returnError.push({ field, errorMessage, errorCategory });
          }
        }
      });
    }

    return returnError;
  }

  checkIfEmailAnReturnError(el, secondMail = true) {
    const emailValue = $(el).val();
    const emailValueToCheck = $(el)
      .closest('.form__row')
      .find('input[type="email"]')
      .not(el)
      .val();
    if (emailValue.length > 0) {
      if (this.options.mailPattern.test(emailValue)) {
        return 'passed';
      } else {
        return this.options.mailSyntax;
      }
    } else {
      return this.options.mailEmpty;
    }
  }

  /**
   * Draw a red border upon every failed element
   * fills the errorcontainer with errormessages
   * @param obj
   */
  putErrorsOnInputField(obj) {
    $(obj).each((i, e) => {
      let html_element = $(`*[name="${e.field}"].required, *[data-error-name="${e.field}"]`);
      $(html_element).addClass(this.options.errorClass);

      $(this.options.errorContainer)
        .children('ul')
        .append(`<li>${e.errorMessage}</li>`);
    });
    $(this.options.errorContainer).addClass('active');
  }

  clearErrorContainer() {
    $('.' + this.options.errorClass).removeClass(this.options.errorClass);
    $(this.options.errorContainer)
      .addClass(this.options.invisibleClass)
      .children('ul')
      .html('');
  }

  isValidDate(dateString) {
    const regEx = /(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d/;
    return dateString.match(regEx); // Invalid format
  }
}
