import $ from 'jquery'
import Dropzone from 'dropzone'
import shortid from 'shortid'

export default class FormDropzone {
  constructor( args) {
    const defaults = {
      uploadUrl: '',
      removeUrl: '',
      initID: '',
      maxFiles: 5,
      maxFilesize: 5,
      detailContainerDataField: '',
      uploadType: 'foil',
      env: '',
      userDir: '',
    }
    this.args = { ...defaults, ...args }
    this.fileName = ''

    this.initDropzone()
  }

  initDropzone() {
    const appClass = this;

    if ($(this.args.initID).length > 0) {
      new Dropzone(this.args.initID, {
        url: this.args.uploadUrl,
        maxFilesize: this.args.MaxFilesize,
        addRemoveLinks: true,
        maxFiles: this.args.maxFiles,
        parallelUploads: 5,
        acceptedFiles: '.png,.jpg,.jpeg,.pdf,.eps,.tif',
        autoProcessQueue: true,
        previewTemplate: document.getElementById('preview-template').innerHTML,
        dictMaxFilesExceeded: 'Sie können maximal 5 Dateien hochladen',
        init: function () {
          this.on('addedfile', (file) => {

            let dropzones = $(`.form-part__file-upload[data-upload-type="${appClass.args.detailContainerDataField}"]`);
    
            $(dropzones).each((index, element) => {
              if($(element).find(appClass.args.initID).length) {
                
                $(element).addClass('has-files')
              }
            })
            const $dzImage = $('.dz-image');
            let displayName = file.name;
            let fileName = `${appClass.args.uploadType}_${shortid.generate()}_${displayName}`
            // Render display name for each item
            let disp = file.previewElement.querySelector('input[name="display_name"]');
            $(disp).val(displayName);
            appClass.fileName = fileName;
            $dzImage.after(`<input type="hidden" name="filename[${fileName}]" value="${fileName}">`);

          });
          this.on('success', (file, response) => {
            response = JSON.parse(response);

            const $currentFilePreview = $(file.previewElement);
            $currentFilePreview.children('.dz-progress, .dz-filename, .dz-size').show();

          });
          this.on('removedfile', ( file ) => {
            $.post( appClass.args.removeUrl, { file: file.name });
          });

          this.on('maxfilesexceeded', (file) => {
            const $currentFilePreview = $(file.previewElement);
          });
        },
        sending: (file, xhr, formData) => {
          formData.append('env', this.args.env)
          formData.append('fileName', `${this.args.uploadType}_${file.name}`)
          formData.append('userDir', this.args.userDir)
          formData.append('uploadType', this.args.detailContainerDataField)
          formData.append('csrf', 'aXYXmQThcBUuK0R231Fa6tfgDQDw3U7r');
          formData.append('action', 'upload_media_file');
        },
      });
    }
  }
}
